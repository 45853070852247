var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, } from "@mui/material";
import { Cloud, Home, Notifications, Photo, RequestPage, VerifiedUser, } from "@mui/icons-material";
export var Menu = function (_a) {
    var onClickItem = _a.onClickItem;
    var onClickHandler = useCallback(function (key) {
        return function () {
            onClickItem === null || onClickItem === void 0 ? void 0 : onClickItem(key);
        };
    }, [onClickItem]);
    return (_jsx(Paper, { children: _jsxs(MenuList, { children: [_jsxs(MenuItem, __assign({ onClick: onClickHandler("/") }, { children: [_jsx(ListItemIcon, { children: _jsx(Home, { fontSize: "small" }) }), _jsx(ListItemText, { children: "In\u00EDcio" })] })), _jsxs(MenuItem, __assign({ onClick: onClickHandler("profile") }, { children: [_jsx(ListItemIcon, { children: _jsx(VerifiedUser, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Perfil do usu\u00E1rio" })] })), _jsx(Divider, {}), _jsxs(MenuItem, __assign({ onClick: onClickHandler("sample-request") }, { children: [_jsx(ListItemIcon, { children: _jsx(RequestPage, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Requisi\u00E7\u00E3o" })] })), _jsxs(MenuItem, __assign({ onClick: onClickHandler("sample-auth-request") }, { children: [_jsx(ListItemIcon, { children: _jsx(Cloud, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Requisi\u00E7\u00E3o autenticada" })] })), _jsxs(MenuItem, __assign({ onClick: onClickHandler("images") }, { children: [_jsx(ListItemIcon, { children: _jsx(Photo, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Imagens" })] })), _jsxs(MenuItem, __assign({ onClick: onClickHandler("notificatiion") }, { children: [_jsx(ListItemIcon, { children: _jsx(Notifications, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Notifica\u00E7\u00F5es" })] }))] }) }));
};
