var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, TextField, Button, Typography, Grid, Card, Snackbar, Alert, } from "@mui/material";
export var Notification = function (_a) {
    var _b, _c;
    var onSubmit = _a.onSubmit, register = _a.register, errors = _a.errors, feedback = _a.feedback, onCloseFeedback = _a.onCloseFeedback, isAuthenticated = _a.isAuthenticated;
    return (_jsxs(Box, { children: [_jsx(Card, __assign({ sx: {
                    py: 2,
                    px: 6,
                } }, { children: _jsx(Grid, __assign({ container: true }, { children: _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "Enviar Notifica\u00E7\u00E3o" })), _jsx("hr", {}), isAuthenticated ? (_jsx("form", __assign({ onSubmit: onSubmit }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, __assign({ label: "CPF", variant: "outlined", fullWidth: true }, register("cpf"), { error: !!errors.cpf, helperText: (_b = errors.cpf) === null || _b === void 0 ? void 0 : _b.message, inputProps: { maxLength: 11 } })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, __assign({ label: "Mensagem de Notifica\u00E7\u00E3o", variant: "outlined", fullWidth: true }, register("message"), { error: !!errors.message, helperText: (_c = errors.message) === null || _c === void 0 ? void 0 : _c.message, multiline: true, rows: 4 })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Button, __assign({ type: "submit", variant: "contained", color: "primary", fullWidth: true }, { children: "Enviar" })) }))] })) }))) : (_jsx(Typography, __assign({ variant: "subtitle1", align: "center" }, { children: "Inicie a sess\u00E3o para enviar uma notifica\u00E7\u00E3o." })))] })) })) })), feedback && (_jsx(Snackbar, __assign({ open: true, autoHideDuration: 4000, onClose: onCloseFeedback }, { children: _jsx(Alert, __assign({ onClose: onCloseFeedback, severity: feedback.type, sx: { width: "100%" } }, { children: feedback.message })) })))] }));
};
