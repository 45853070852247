var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useWebgate } from "@xvia/webgate-connect";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Notification } from "./Notification";
var schema = yup.object().shape({
    cpf: yup
        .string()
        .length(11, "O CPF deve ter 11 dígitos.")
        .required("CPF é obrigatório"),
    message: yup.string().required("A mensagem é obrigatória"),
});
export var NotificationContainer = function () {
    var accessToken = useWebgate().accessToken;
    var _a = React.useState(null), feedback = _a[0], setFeedback = _a[1];
    var _b = useForm({
        resolver: yupResolver(schema),
    }), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors, reset = _b.reset;
    var sendNotification = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = process.env.REACT_APP_API_URL;
                    return [4 /*yield*/, fetch("".concat(url, "/v1/notification"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(payload),
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); };
    var onSubmit = function (_a) {
        var cpf = _a.cpf, message = _a.message;
        return __awaiter(void 0, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, sendNotification({
                                recipientCpf: cpf,
                                messageContent: message,
                            })];
                    case 1:
                        response = _b.sent();
                        if (!response.ok)
                            throw new Error("Erro ao enviar a notificação");
                        setFeedback({
                            type: "success",
                            message: "Notificação enviada com sucesso!",
                        });
                        reset();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error("Erro ao enviar a notificação:", error_1);
                        setFeedback({
                            type: "error",
                            message: "Falha ao enviar a notificação. Tente novamente.",
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    var onCloseFeedback = function () {
        setFeedback(null);
    };
    return (_jsx(Notification, { onSubmit: handleSubmit(onSubmit), register: register, errors: errors, feedback: feedback, onCloseFeedback: onCloseFeedback, isAuthenticated: !!accessToken }));
};
